import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  HttpOptions,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { captureMessage, Severity } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { GraphQLError } from "graphql";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  TOKEN_INVALID,
  TOKEN_EXPIRED,
  ROLE_INVALID,
} from "@ds-developer/constants";
import {
  APPSYNCCLIENT_MAX_RETRIES,
  JourneyType,
  NEW_USER_JOURNEY_PATH_NAMES,
  REDIRECT_URL_PARAM,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "./constants";
import { useSnackbar } from "notistack";
import AccessToken from "./graphql/accessToken.graphql";
import { Brand, Service } from "@graphql/types";
import { getCookie, getJourneyType } from "@utils";
import { StateContext } from "models/state";
import { useStateContext } from "@context";

const createRetryLink = () =>
  new RetryLink({
    attempts: (count, operation, error) => {
      if (count === APPSYNCCLIENT_MAX_RETRIES + 1)
        captureMessage(
          `Client retried ${operation.operationName} ${APPSYNCCLIENT_MAX_RETRIES} times with error: ${error.message}`,
          Severity.Warning,
        );
      return true;
    },
  });

const getAuth = (journeyType: JourneyType) => {
  const authPortalToken = getCookie("authPortalToken");
  const publicAuth = localStorage.getItem("publicAuth");
  const learnerAuth = localStorage.getItem("learnerAuth");
  if (USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY) {
    return authPortalToken;
  }

  if (
    window._env_.REACT_APP_environment === "local" &&
    process.env.REACT_APP_GATEWAY_AUTHORIZATION_OVERRIDE
  ) {
    return process.env.REACT_APP_GATEWAY_AUTHORIZATION_OVERRIDE;
  }

  return (
    learnerAuth || publicAuth || window._env_.REACT_APP_initial_access_token_key
  );
};

const createSessionLink = ({
  sessionId,
  journeyType,
}: {
  sessionId: string;

  journeyType: JourneyType;
}) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: any }) => {
      headers = {
        ...headers,
        authorization: getAuth(journeyType),
      };
      return {
        headers: {
          "x-session-id": sessionId,
          ...headers,
        },
      };
    });
    return forward(operation);
  });

const errorLink = (
  graphQLError?: string,
  setGraphQLError?: (error: string) => void,
) =>
  onError(({ graphQLErrors, response, operation }) => {
    if (graphQLErrors && response) {
      response.errors = graphQLErrors.map(err => {
        Sentry.captureException(
          new Error(
            `${operation.operationName} failed with error: ${err.message}`,
          ),
          {
            extra: { operation },
          },
        );
        setGraphQLError && !graphQLError && setGraphQLError(err.message);
        return new GraphQLError(JSON.stringify(err));
      });
    }
  });

export const createClient = ({
  sessionId,
  options,
  graphQLError,
  setGraphQLError,
  journeyType,
}: {
  sessionId: string;
  options: HttpOptions;
  graphQLError?: string;
  setGraphQLError?: (error: string) => void;
  journeyType: JourneyType;
}) => {
  const link = ApolloLink.from([
    errorLink(graphQLError, setGraphQLError),
    createSessionLink({ sessionId, journeyType }),
    createRetryLink(),
    new HttpLink({ uri: options.uri, credentials: options.credentials }),
  ]);

  return new ApolloClient({ link, cache: new InMemoryCache() });
};

export const redirectTarget = (brand: Brand, journeyType: JourneyType) => {
  if (USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY) {
    return brand === Brand.Aads
      ? window._env_.REACT_APP_AUTH_PORTAL_URL_AA
      : window._env_.REACT_APP_AUTH_PORTAL_URL_BSM;
  }

  return brand === Brand.Aads
    ? window._env_.REACT_WEB_JOURNEY_AA
    : window._env_.REACT_WEB_JOURNEY_BSM;
};

export const gatewayClient = ({
  sessionId,
  graphQLError,
  setGraphQLError,
  journeyType,
}: {
  sessionId: string;
  graphQLError?: string;
  setGraphQLError?: (error: string) => void;
  journeyType: JourneyType;
}) =>
  createClient({
    sessionId,
    options: {
      uri: window._env_.REACT_APP_gateway_graphqlEndpoint,
    },
    graphQLError,
    setGraphQLError,
    journeyType,
  });

export const ApolloClientProvider = ({
  sessionId,
  children,
}: {
  sessionId: string;
  children: React.ReactNode;
}) => {
  const [graphQLError, setGraphQLError] = useState("");
  const history = useHistory();
  const { state }: StateContext = useStateContext();

  const journeyType = getJourneyType(history.location.pathname, state);

  const { enqueueSnackbar } = useSnackbar();

  const client = useMemo(
    () =>
      gatewayClient({ sessionId, graphQLError, setGraphQLError, journeyType }),
    [],
  );

  useEffect(() => {
    if (journeyType !== JourneyType.RETURNING_USER_JOURNEY) {
      getAccessToken(client);
    }
  }, []);

  useEffect(() => {
    if (!graphQLError) {
      return;
    }

    const learnerAuth =
      USE_AUTH_PORTAL && journeyType === JourneyType.RETURNING_USER_JOURNEY
        ? getCookie("authPortalToken")
        : localStorage.getItem("learnerAuth");

    const path = history.location.pathname;

    if (path.includes("theory-app")) {
      return;
    }

    // We've tried to use the token in the cookie but it's been kicked back
    // by API Gateway ...
    if ([TOKEN_INVALID, TOKEN_EXPIRED, ROLE_INVALID].includes(graphQLError)) {
      localStorage.removeItem("publicAuth");

      if (
        learnerAuth !== null ||
        path.includes("returning") ||
        path.includes("account")
      ) {
        if (
          USE_AUTH_PORTAL &&
          journeyType === JourneyType.RETURNING_USER_JOURNEY
        ) {
          window.location.href = redirectTarget(
            window._env_.REACT_APP_brand as Brand,
            journeyType,
          );
          return;
        }
        localStorage.removeItem("learnerAuth");
        if (path !== RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN) {
          history.push(
            `${RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN}?${REDIRECT_URL_PARAM}=${path}`,
          );
          return;
        }
      } else {
        const publicRoutes = [
          NEW_USER_JOURNEY_PATH_NAMES.PICKUP,
          RETURNING_USER_JOURNEY_PATH_NAMES.RECOMMENDED_DRIVING_PRODUCTS,
          RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN,
        ];

        if (!publicRoutes.includes(path)) {
          history.push(NEW_USER_JOURNEY_PATH_NAMES.PICKUP);
          return;
        }
      }

      if (graphQLError === ROLE_INVALID) {
        enqueueSnackbar(
          `Missing permissions, please try logging in or restarting the Journey.`,
          {
            variant: "error",
            autoHideDuration: 3000,
          },
        );
      } else if (graphQLError !== TOKEN_EXPIRED) {
        enqueueSnackbar(
          `Could not authenticate request, please try again. ${graphQLError}`,
          {
            variant: "error",
            autoHideDuration: 3000,
          },
        );
      }
    } else if (learnerAuth !== null) {
      history.goBack();
      enqueueSnackbar(graphQLError, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(graphQLError, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    getAccessToken(client);
  }, [graphQLError]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const getAccessToken = async (client: any) => {
  try {
    const { data, errors } = await client.query({
      query: AccessToken,
      variables: {
        service: Service.DrivingLessons,
      },
    });

    if (errors) {
      return;
    }

    localStorage.setItem("publicAuth", data.accessToken);
  } catch {}
};
