export const getCookie = (name: string) => {
  const cookieArray = document.cookie.split(";"); // Split the cookie string into individual cookies
  let cookieValue = null;

  cookieArray.forEach(cookie => {
    const [key, value] = cookie.trim().split("="); // Split each cookie into key and value
    if (key === name) {
      cookieValue = value; // If the cookie name matches, store its value
    }
  });

  return cookieValue;
};

export const removeCookie = (name: string) => {
  const rootDomain =
    location.hostname.split(".").reverse()[1] +
    "." +
    location.hostname.split(".").reverse()[0];

  document.cookie =
    name + `=; domain=${rootDomain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
